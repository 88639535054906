import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/Layout";
import michelle from "../img/michelle.png";

const IndexPage = () => (
  <Layout>
    <Helmet title={`About | Michelle Pakla`} />
    <div className="content content__project">
      <div className="content__container">
        <h1>About</h1>
        <div className="project__container">
            <div className="project__intro">
              <img className="profileimage" src={michelle} alt="Michelle surrounded by beautiful lilac blossoms" />
              <h2>Who am I?</h2>
              <p>I live just outside of beautiful Greenville, SC on about an acre of 
                land with my husband, 4 cats, 2 goats, and 6 chickens. It's definitely a full house. 
                We are incredibly passionate about gardening and recently had our property certified 
                as a natural wildlife habitat.</p>
              <p>If I'm not in the garden, you might find me birding, hiking the Blue Ridge Mountains, 
                working on miniatures, storming castles and raiding dragon hoards with the best DnD 
                group this side of the Mississippi, or attempting to get a high score on a pinball machine. 
                Tales of Arabian Nights is by far the best pinball machine there is. Trust me.</p>
              <h2>Yeah, but what about web design?</h2>  
              <p>As a student, my two favorite subjects were art and math. I know, you would expect art 
                and English, but I am genuinely terrible at writing and math always just made way more sense to me. 
                I think this is why I am so passionate about web design and front-end development. It combines 
                the creativity of the artistic side with the logic, problem solving mathematics side. 
                Even though I am no longer a student in the school sense, I am still eager to learn new 
                technologies within the field and strive to continue building my skill set.</p>
            </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage